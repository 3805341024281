import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { ContainerColumn, ContainerRow } from '@src/components/styled';
import { Title } from '@src/components/styled/Typography';
import Button from '@shared/ui/buttons/Button';
import { WithSkeleton } from '@shared/ui/progress-loaders/Skeleton';
import IncomeInfo from './IncomeInfo';
import IncomeDetails from './IncomeDetails';
import SubscriptionConversionGraph from './SubscriptionConversionGraph';
import TotalIncomeGraph from './TotalIncomeGraph';
import AssetsGraph from './AssetsGraph';
const ManagementIncomeContainer = styled(ContainerColumn)(props => ({
    height: 'auto',
    gap: props.theme.spacing_sizes.m,
    padding: `${props.theme.spacing_sizes.s * 5}px 0px`,
}));
const ManagementIncomeInner = styled(ContainerRow)(props => ({
    justifyContent: 'space-between',
    gap: props.theme.spacing_sizes.l,
}));
const InnerColumn = styled(ContainerColumn)(props => ({
    gap: props.theme.spacing_sizes.m,
    maxWidth: 312,
}));
const ManagementIncome = ({ isLoading = false, conversionData = [], incomeData = [], assetsData = [], conversionDataDefaultRange, incomeDataDefaultRange, assetsDataDefaultRange, totalIncome, totalOutcome, }) => {
    const { t } = useTranslation();
    return (_jsxs(ManagementIncomeContainer, { children: [_jsx(WithSkeleton, { isLoading: isLoading, width: 154, height: 36, children: _jsx(Title, { children: t('balance.trader_balance.management_income.title') }) }), _jsxs(ManagementIncomeInner, { children: [_jsxs(InnerColumn, { children: [_jsx(IncomeInfo, { amount: 0, isLoading: isLoading }), _jsx(IncomeDetails, { totalIncome: totalIncome, totalOut: totalOutcome, isLoading: isLoading }), _jsx(WithSkeleton, { isLoading: isLoading, width: '100%', height: 40, children: _jsx(Button, { fullWidth: true, disabled: totalIncome === 0 && totalOutcome === 0, children: t('balance.trader_balance.management_income.balance_management') }) })] }), _jsx(SubscriptionConversionGraph, { data: conversionData, defaultRange: conversionDataDefaultRange, isLoading: isLoading }), _jsx(TotalIncomeGraph, { data: incomeData, defaultRange: incomeDataDefaultRange, isLoading: isLoading }), _jsx(AssetsGraph, { data: assetsData, defaultRange: assetsDataDefaultRange, isLoading: isLoading })] })] }));
};
export default ManagementIncome;
